import React, { useCallback, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Container, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Layout from '../../components/Layout'
import api from '../../utils/api'

const formSchema = yup.object().shape({
	email: yup
		.string()
		.trim()
		.email('Must be a valid email address')
		.required('Email is required'),
	code: yup.string().trim(),
})

const useStyles = makeStyles(
	theme => ({
		root: {
			alignItems: 'center',
			border: '1px solid grey',
			borderRadius: theme.spacing(2),
			display: 'flex',
			justifyContent: 'center',
			marginBottom: theme.spacing(8),
			marginTop: theme.spacing(4),
			paddingBottom: theme.spacing(3),
			paddingTop: theme.spacing(3),
		},
	}),
	{ name: 'RequestAccountDeletion' }
)

const Stack = ({ children, spacing }) => (
	<div style={{ display: 'flex', flexDirection: 'column', gap: spacing }}>
		{children}
	</div>
)

const EmailCaptureForm = ({ isPending }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext()

	const { ref, ...inputProps } = register('email')

	return (
		<Stack spacing={3}>
			<Typography>Sorry to see you go.</Typography>
			<Typography>
				If there's anything we can do to change your mind, please reach
				out to us at help@tyto.me.
			</Typography>
			<Typography variant={'body2'}>
				To initiate the deletion of your account, please provide the
				email address you use on Tyto below.
			</Typography>
			<TextField
				variant={'outlined'}
				label={'Email'}
				error={Boolean(errors['email'])}
				helperText={errors['email']?.message ?? ''}
				inputRef={ref}
				{...inputProps}
			/>
			<Typography>
				If it is a valid email account within our system, we will send
				you a verification code by email — to make sure it's actually
				you.
			</Typography>
			<Typography>
				Then, once you have typed in the confirmation code into this
				form, we will start the account deletion process.
			</Typography>

			<Button type={'submit'} loading={isPending} variant={'outlined'}>
				Delete my account
			</Button>
		</Stack>
	)
}

const ConfirmationCodeForm = ({ isPending }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext()

	const { ref, ...inputProps } = register('code')

	return (
		<>
			<Typography variant={'h6'}>
				Enter the confirmation code we just sent to your email.
			</Typography>
			<TextField
				variant={'outlined'}
				label={'Confirmation Code'}
				error={Boolean(errors['code'])}
				helperText={errors['code']?.message ?? ''}
				inputRef={ref}
				{...inputProps}
			/>
			<Button type={'submit'} loading={isPending} variant={'outlined'}>
				Send confirmation email
			</Button>
		</>
	)
}

const RequestAccountDeletion = () => {
	const [status, setStatus] = useState('idle')
	const [error, setError] = useState('')
	const [isPending, setIsPending] = useState(false)
	const formContext = useForm({ resolver: yupResolver(formSchema) })
	const classes = useStyles()

	const submit = useCallback(data => {
		if (data.code) {
			setIsPending(true)
			api.confirmAccountDeletion(data)
				.then(() => {
					setStatus('success')
				})
				.catch(err => {
					console.error(err)
					setStatus('failed')
					setError('Sending account deletion request failed')
				})
				.finally(() => {
					setIsPending(false)
				})
		} else if (data.email) {
			setStatus('pending')
			api.requestAccountDeletion({ email: data.email })
				.then(() => {
					setStatus('awaitingCode')
				})
				.catch(err => {
					console.error(err)
					setStatus('failed')
					setError('Validating confirmation code failed')
				})
				.finally(() => {
					setIsPending(false)
				})
		}
	}, [])

	const onErrors = useCallback(errors => {
		console.error(errors)
	}, [])

	return (
		<Layout>
			<Container className={classes.root}>
				<FormProvider {...formContext}>
					<form onSubmit={formContext.handleSubmit(submit, onErrors)}>
						<Stack spacing={3}>
							<Typography variant={'h4'}>
								Account Deletion
							</Typography>

							{status === 'success' ? (
								<Typography variant={'h6'}>
									The account deletion process has begun. We
									will be in contact to finish off the
									process.
								</Typography>
							) : status === 'failed' ? (
								<Typography align={'center'}>
									{error}
								</Typography>
							) : status === 'awaitingCode' ? (
								<ConfirmationCodeForm isPending={isPending} />
							) : (
								<EmailCaptureForm isPending={isPending} />
							)}
						</Stack>
					</form>
				</FormProvider>
			</Container>
		</Layout>
	)
}

export default RequestAccountDeletion
